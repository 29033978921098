import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-jaBwibxaj2PV-GtxvZBRuqJOgJllWDE",
  authDomain: "okay-training.firebaseapp.com",
  projectId: "okay-training",
  storageBucket: "okay-training.appspot.com",
  messagingSenderId: "678328203466",
  appId: "1:678328203466:web:e15ba58276f89bf8d75c33",
  measurementId: "G-V2ENZEF5ZL",
};

const fire = initializeApp(firebaseConfig);

export default fire;
export const db = getFirestore(fire);
