/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase-config";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { collection, getDocs, addDoc } from "@firebase/firestore";

import emailjs from "emailjs-com";

export default function Index() {
  const [curso, setCurso] = useState("");
  const [edicao, setEdicao] = useState("");
  const [nome, setNome] = useState("");
  const [bi, setBi] = useState("");
  const [validade, setValidade] = useState("");
  const [contribuinte, setContribuinte] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [morada, setMorada] = useState("");
  const [codigopostal, setCodigopostal] = useState("");
  const [localidade, setLocalidade] = useState("");
  const [telemovel, setTelemovel] = useState("");
  const [email, setEmail] = useState("");
  const [qualificacao, setQualificacao] = useState("");
  const [situacao, setSituacao] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [grau, setGrau] = useState("");
  const [okay, setOkay] = useState("");

  const [inscritos, setInscritos] = useState([]);
  const inscritosCollectionRef = collection(db, "Inscritos");

  const createInscrito = async () => {
    await addDoc(inscritosCollectionRef, {
      curso: curso,
      edicao: edicao,
      nome: nome,
      bi: bi,
      validade: validade,
      contribuinte: contribuinte,
      nascimento: nascimento,
      nacionalidade: nacionalidade,
      morada: morada,
      codigopostal: codigopostal,
      localidade: localidade,
      telemovel: telemovel,
      email: email,
      qualificacao: qualificacao,
      situacao: situacao,
      empresa: empresa,
      grau: grau,
      okay: okay,
    });
  };

  //Envio de Email

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_w07tkpo",
        "template_ieo218b",
        form.current,
        "user_RqEuIWMBU7JvJTs4ctrJy"
      )
      .then(
        (result) => {
          alert("Inscrição enviada com sucesso.");
        },
        (error) => {
          alert(error.message);
        }
      );

    emailjs.sendForm(
      "service_w07tkpo",
      "template_yqhmxbg",
      form.current,
      "user_RqEuIWMBU7JvJTs4ctrJy"
    );

    e.target.reset();
  };

  //Fim do envio de email

  useEffect(() => {
    const getInscritos = async () => {
      const data = await getDocs(inscritosCollectionRef);
      setInscritos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getInscritos();
  }, []);

  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Training of Innovators?
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Na Okay lecionamos formações em diferentes áreas do conhecimento
                destinadas a inovadores!
              </p>
              <div className="mt-12">
                <a
                  href="#inscrever"
                  target="_self"
                  style={{ backgroundColor: "#d35e04" }}
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Inscreva-se agora
                </a>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/Fundo-Pag-Inscricao.jpg").default}
          alt="..."
        />
      </section>
      <form ref={form} onSubmit={sendEmail}>
        <section
          className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100"
          id="inscrever"
          style={{ backgroundColor: "#FFFF" }}
        >
          <div className="container mx-auto"></div>

          <div className="justify-center text-center flex flex-wrap">
            <div
              className="w-full md:w-6/12 px-12 md:px-4"
              style={{ marginTop: 30 }}
            >
              <h2 className="font-semibold text-4xl">Ficha de Inscrição</h2>
              <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                Preenche o formulário abaixo e fica atento ao teu e-mail
              </p>
            </div>
          </div>
          <hr />
          <div
            className="w-full md:w-12/12 px-12 md:px-4"
            style={{
              textAlign: "center",
              marginBottom: "-140px",
              marginTop: 30,
            }}
          >
            <h6 className="font-semibold text-2xl">
              Informações sobre o curso
            </h6>
          </div>
          <div className="container mx-auto px-4 pb-32 pt-48">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Designação do Curso:
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Name"
                    name="curso"
                    required
                    onChange={(event) => {
                      setCurso(event.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Selecione o curso
                    </option>
                    <option value="comportamento_do_consumidor">
                      Comportamento do Consumidor
                    </option>
                    <option value="formacao_de_equipas_para_teletrabalho">
                      Formação de Equipas para Teletrabalho
                    </option>
                    <option value="tecnicas_de_negociacao">
                      Técnicas de Negociação
                    </option>
                    <option value="design_Thinking">Design Thinking</option>
                    <option value="lideranca_e_inovacao">
                      Liderança e Inovação
                    </option>
                    <option value="marcas_e_produtos_inovadores">
                      Marcas e Produtos Inovadores
                    </option>
                    <option value="business_plan">Business Plan</option>
                    <option value="excel_inicial">Excel Inicial</option>
                    <option value="excel_intermedio">Excel Intermédio</option>
                    <option value="gestao_de_financas_pessoais">
                      Gestão de Finanças Pessoais
                    </option>
                    <option value="linkdin-a_tua_marca_pessoal">
                      LinkdIn - A tua Marca Pessoal
                    </option>
                    <option value="animacao_de_personagens">
                      Animação de Personagens
                    </option>
                    <option value="power_bi">Power BI</option>
                    <option value="python">Python</option>
                    <option value="xamarin">Xamarin</option>
                  </select>
                </div>
              </div>

              {/*<div className="w-full md:w-8/12 ml-auto px-12 md:px-4">
                <div className="md:pr-12">
                  <div className="form-row">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Designação do Curso:
                      </label>
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        name="curso"
                        required
                        onChange={(event) => {
                          setCurso(event.target.value);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Selecione o curso
                        </option>
                        <option value="comportamento_do_consumidor">
                          Comportamento do Consumidor
                        </option>
                        <option value="formacao_de_equipas_para_teletrabalho">
                          Formação de Equipas para Teletrabalho
                        </option>
                        <option value="tecnicas_de_negociacao">
                          Técnicas de Negociação
                        </option>
                        <option value="design_Thinking">Design Thinking</option>
                        <option value="lideranca_e_inovacao">
                          Liderança e Inovação
                        </option>
                        <option value="marcas_e_produtos_inovadores">
                          Marcas e Produtos Inovadores
                        </option>
                        <option value="business_plan">Business Plan</option>
                        <option value="excel_inicial">Excel Inicial</option>
                        <option value="excel_intermedio">
                          Excel Intermédio
                        </option>
                        <option value="gestao_de_financas_pessoais">
                          Gestão de Finanças Pessoais
                        </option>
                        <option value="linkdin-a_tua_marca_pessoal">
                          LinkdIn - A tua Marca Pessoal
                        </option>
                        <option value="animacao_de_personagens">
                          Animação de Personagens
                        </option>
                        <option value="power_bi">Power BI</option>
                        <option value="python">Python</option>
                        <option value="xamarin">Xamarin</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <div className="w-full md:w-4/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nº da edição do curso:
                  </label>
                  <input
                    type="text"
                    name="curso"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Digite o número da edição"
                    onChange={(event) => {
                      setEdicao(event.target.value);
                    }}
                  />
                </div>
                  </div>*/}

              <div
                className="w-full md:w-12/12 px-12 md:px-4 mb-1 mt-10"
                style={{ textAlign: "center" }}
              >
                <h6 className="font-semibold text-2xl">
                  Informações sobre o Aluno
                </h6>
              </div>

              <div className="w-full md:w-12/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    name="nome"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Digite o teu nome"
                    onChange={(event) => {
                      setNome(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-4/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    BI/CC/PPT
                  </label>
                  <input
                    type="number"
                    name="bi"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="000000000"
                    onChange={(event) => {
                      setBi(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto px-12 md:px-4">
                <div className="relative w-fufll mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Data de Validade
                  </label>
                  <input
                    required
                    type="date"
                    name="validade"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Digite a Data de Validade do seu BI/CC"
                    onChange={(event) => {
                      setValidade(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-4/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nº de contribuinte
                  </label>
                  <input
                    required
                    type="number"
                    name="contribuinte"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="0000000.00"
                    onChange={(event) => {
                      setContribuinte(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Data de Nascimento
                  </label>
                  <input
                    required
                    type="date"
                    name="nascimento"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="0000.0.0000"
                    onChange={(event) => {
                      setNascimento(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nacionalidade
                  </label>
                  <input
                    required
                    name="nacionalidade"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: Portuguesa"
                    onChange={(event) => {
                      setNacionalidade(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-12/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Morada
                  </label>
                  <input
                    required
                    type="text"
                    name="morada"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: Avenida Infante Dom Henrique, 89 - Santa Apolónia"
                    onChange={(event) => {
                      setMorada(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-8/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Código Postal
                  </label>
                  <input
                    type="number"
                    required
                    name="codigopostal"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: 1900-000"
                    onChange={(event) => {
                      setCodigopostal(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-4/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Localidade
                  </label>
                  <input
                    type="text"
                    required
                    name="localidade"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: Lisboa"
                    onChange={(event) => {
                      setLocalidade(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Telemóvel
                  </label>
                  <input
                    required
                    type="number"
                    name="telemovel"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: 000 000 000"
                    onChange={(event) => {
                      setTelemovel(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    E-mail
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: nome@dominio.pt"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div
                className="w-full md:w-12/12 px-12 md:px-4 mb-1 mt-10"
                style={{ textAlign: "center" }}
              >
                <h6 className="font-semibold text-2xl">
                  Informações Académica
                </h6>
              </div>

              <div className="w-full md:w-12/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Qual a tua qualificação académica?
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Name"
                    name="qualificacao"
                    required
                    onChange={(event) => {
                      setQualificacao(event.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Seleciona uma das opções
                    </option>
                    <option value="Eu tenho até ao 12º ano">
                      Eu tenho até ao 12º ano
                    </option>
                    <option value=" Eu tenho uma Licenciatura">
                      Eu tenho uma licenciatura
                    </option>
                    <option value="Eu tenho um Mestrado">
                      Eu tenho um Mestrado
                    </option>
                    <option value="Eu tenho um Doutoramento">
                      Eu tenho um Doutoramento
                    </option>
                  </select>
                </div>
              </div>

              <div
                className="w-full md:w-12/12 px-12 md:px-4 mb-1 mt-10"
                style={{ textAlign: "center", marginBottom: 40 }}
              >
                <h6 className="font-semibold text-2xl">
                  Qual a tua situação atual?
                </h6>
              </div>

              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Hoje eu estou...
                  </label>
                  <select
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Name"
                    name="situacao"
                    onChange={(event) => {
                      setSituacao(event.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Selecione uma das opções
                    </option>
                    <option value="A estudar">A estudar</option>
                    <option value="Empregado">Empregado</option>
                    <option value="Desempregado">Desempregado</option>
                  </select>
                </div>
              </div>
              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Se estás empregado, diz-nos em que empresa trabalhas.
                  </label>
                  <input
                    required={situacao === "Empregado"}
                    type="text"
                    name="empresa"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Ex.: KnowledgeBiz"
                    onChange={(event) => {
                      setEmpresa(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Como classificas o teu grau de conhecimento na área?
                  </label>
                  <select
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Name"
                    name="grau"
                    onChange={(event) => {
                      setGrau(event.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Seleciona uma das opções
                    </option>
                    <option value="Nulo">Nulo</option>
                    <option value="Baixo">Baixo</option>
                    <option value="Médio">Médio</option>
                    <option value="Alto">Alto</option>
                  </select>
                </div>
              </div>
              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Já conhecias a Okay?
                  </label>
                  <select
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Name"
                    name="okay"
                    onChange={(event) => {
                      setOkay(event.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Seleciona uma das opções
                    </option>
                    <option value="nao">Não</option>
                    <option value="Sim, vi nas redes sociais">
                      Sim, vi nas redes sociais
                    </option>
                    <option value="Sim, vi no Google">Sim, vi no Google</option>
                    <option value="Sim, recebi uma indicação de amigos e/ou parentes">
                      Sim, recebi uma indicação de amigos e/ou parentes
                    </option>
                  </select>
                </div>
              </div>

              <div
                className="w-full md:w-12/12 ml-auto px-12 md:px-4"
                style={{ marginTop: 30 }}
              >
                <div className="relative w-full mb-3">
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        required
                        className="form-checkbox  rounded text-blueGray-700 ml-1 w-5 h-5 border-0 px-3 py-3 placeholder-black-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Confirmo que aceito o armazenamento dos meus dados
                        pessoais para fins legalmente exigíveis à empresa.{" "}
                        <a
                          href="https://okay-training.pt/politica-de-privacidade/"
                          className="text-lightBlue-500"
                          target="_blank"
                        >
                          Política de Privacidade
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-12/12 ml-auto px-12 md:px-4">
                <div className="relative w-full mb-3">
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        required
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Aceito que os meus dados pessoais sejam recolhidos e
                        armazenados para efeitos de marketing e comunicação.{" "}
                        <a
                          href="#"
                          className="text-lightBlue-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          GDPR
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-10/10 ml-auto px-12 md:px-4">
                <div className="text-center mt-1">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150"
                    type="submit"
                    style={{ backgroundColor: "#d35e04" }}
                    onClick={createInscrito}
                  >
                    Inscrever
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <section
        className="block relative z-1"
        style={{ backgroundColor: "#F49962" }}
      >
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Business Plan
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/business-plan/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/login.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Técnicas de Negociação
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/tecnicas-de-negociacao/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={
                          require("assets/img/tec-negociotec_negociac.jpg")
                            .default
                        }
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Design Thinking
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/design-thinking/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/thinking.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5
                    className="text-xl font-semibold pb-4 text-center"
                    style={{ color: "#FFF" }}
                  >
                    Animação de Personagens
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/animacao-de-personagens/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/anima.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5
                    className="text-xl font-semibold pb-4 text-center"
                    style={{ color: "#FFF" }}
                  >
                    Power BI
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/power-bi/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/powre.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5
                    className="text-xl font-semibold pb-4 text-center"
                    style={{ color: "#FFF" }}
                  >
                    Xamarin
                  </h5>
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://okay-training.pt/xamarin/"
                    target="_blank"
                  >
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/xamarin.jpg").default}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-20  overflow-hidden"
        style={{ backgroundColor: "#F49962" }}
      ></section>

      <section className="bg-blueGray-200 relative ">
        <div
          className="-mt-20 top-0 bottom-0 left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        {/* <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
        <div className="container mx-auto">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p>
              <h3 className="font-semibold text-3xl">Gostaste da Okay?</h3>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Na Okay pretendemos adicionar o conhecimento e a experiência à
                inovação. As nossas formações assentam num espírito jovem,
                curioso, descontraído, mas comprometidas com a aprendizagem e a
                inovação. “Okay” é a nossa forma de estar mas sempre com vista
                ao “knowledge”.
              </p>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="#inscrever"
                  target="_self"
                  style={{ backgroundColor: "#d35e04" }}
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Começa já!
                </a>
                <a
                  href="https://okay-training.pt/"
                  target="_blank"
                  style={{ backgroundColor: "#f39961" }}
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fas fa-globe text-lg mr-1"></i>
                  <span> Visite o nosso site</span>
                </a>
              </div>
              <div className="text-center mt-16"></div>
                      </div>
                      </div>
        </div>*/}
      </section>
      <Footer />
    </>
  );
}
