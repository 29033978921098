/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="https://okay-training.pt"
            >
              <img src={Logo} width="90" />
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {/*<li className="flex items-center">
                <IndexDropdown />
          </li>*/}
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.facebook.com/Okay.Training.Innovators"
                  style={{ color: "#91350b" }}
                  target="_blank"
                >
                  <i
                    className="text-blueGray-400 fab fa-facebook text-lg leading-lg "
                    style={{ color: "#91350b" }}
                  />
                  <span className="lg:hidden inline-block ml-2">Facebook</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.instagram.com/okay_training/"
                  style={{ color: "#91350b" }}
                  target="_blank"
                >
                  <i
                    className="text-blueGray-400 fab fa-instagram text-lg leading-lg "
                    style={{ color: "#91350b" }}
                  />
                  <span className="lg:hidden inline-block ml-2">Instagram</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-500 text-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  style={{ color: "#91350b" }}
                  href="https://www.linkedin.com/company/okay-training"
                  target="_blank"
                >
                  <i
                    className="text-400 fab fa-linkedin text-lg leading-lg "
                    style={{ color: "#91350b" }}
                  />
                  <span className="lg:hidden inline-block ml-2">Linkedin</span>
                </a>
              </li>

              {/*<Link to="/auth/login">*/}
              <li className="flex items-center">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  style={{ backgroundColor: "#d35e04" }}
                >
                  <i className="fas fa-lock"></i> Painel de formações
                </button>
              </li>
              {/*</Link>*/}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
